// @ts-ignore
import {app, authenticate, authentication} from '@microsoft/teams-js';

export default class TeamsLogin {
    private loader: HTMLElement;
    private loaderText: HTMLElement;
    constructor() {
        this.loader = document.querySelector("#loading-bg") as HTMLElement;
        this.loaderText = (this.loader.querySelector("span") as HTMLElement)
        this.loaderText.innerHTML = "Logge ein..."
        this.setupTeamsClient();
    }

    async setupTeamsClient() {
        try {
            await app.initialize();
        } catch(e: any) {
            this.loaderText.innerHTML = `<div class="alert alert-danger" role="alert">${e.message}</div>`
        }
        const authTokenRequest = {
            successCallback: async (token: string) => {
                document.location.href = "/teams/login/success?ad_token="+token
            },
            failureCallback: (error: string) => {
                this.loaderText.innerHTML = `<div class="alert alert-danger" role="alert">${error}</div>`
            }
        };
        await authentication.getAuthToken(authTokenRequest);
    }
}